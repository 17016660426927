<template>

  <!-- In progress -->
  <div v-if="inprogress" class="text-center">
    <h2>{{ inprogresstxt}}</h2>
    <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
  </div>

  <div v-else class="row">
    <div class="col-md-12">
      <h4 class="title">{{ $t('vpsbilling') }}</h4>
      <!--
      <p class="category">

       </p>
       -->
    </div>
    <div class="col-md-12 card">
      <!--
      <div class="card-header">
        <div class="category">Extended tables</div>
      </div>
      -->
      <div class="card-body row">
        <div class="col-sm-2">
          <el-select class="select-default" v-model="billmonth.month" :placeholder="permonthTxt"
                     v-on:change="getBillMonth">
            <el-option
              class="select-default"
              v-for="item in billmonth.months"
              :key="item.prop" :label="item.label" :value="item.prop">
            </el-option>
          </el-select>
        </div>

        <div class="col-sm-2">
          <el-select class="select-default" v-model="billyear.year" :placeholder="peryearTxt"
                     v-on:change="getBillYear">
            <el-option
              class="select-default"
              v-for="item in billyear.years"
              :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>

        <div class="col-sm-2">
          <el-select class="select-default" v-model="sortby.column" :placeholder="sortbyTxt"
                     v-on:change="sortByCol">
            <el-option class="select-default"
                       v-for="item in sortby.columns"
                       :key="item.prop" :label="item.label" :value="item.prop">
            </el-option>
          </el-select>
        </div>

        <div class="col-sm-2">
          <el-select
            class="select-default" v-model="pagination.perPage" :placeholder="perpageTxt">
            <el-option class="select-default"
                       v-for="item in pagination.perPageOptions"
                       :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>

        <div class="col-sm-2">
          <el-select
            class="select-default" v-model="sortby.order" :placeholder="sortbyOrder"
            v-on:change="sortByCol">
            <el-option class="select-default"
                       v-for="item in sortby.orders"
                       :key="item.prop" :label="item.label" :value="item.prop">
            </el-option>
          </el-select>
        </div>

        <div class="col-sm-2">
          <div class="pull-right">
            <fg-input class="input-sm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
            </el-table-column>

          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'

let bills = [];

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(require('vue-moment'))

export default{
  components: {
    PPagination
  },
  computed: {
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData () {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.pagedData
      }
      let result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            //let rowValue = row[key].toString()
            let searchRegex = new RegExp(this.searchQuery, 'gi');
            //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            if (row[key].toString().match(searchRegex) !== null) {
              isIncluded = true
            }
          }
          return isIncluded
        })
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  data () {
    return {
      perpageTxt: this.$t('perpage'), sortbyTxt: this.$t('sortby'), permonthTxt: this.$t('permonth'),
      colSort: 'bill_start', inprogress: true, inprogresstxt: this.$t('loadingvpsbill'), peryearTxt: this.$t('peryear'), sortbyOrder: this.$t('sortbyorder'),
      billyear: {
        year: this.$moment().format("YYYY"),
        years: [ '2018', '2019' ]
      },
      billmonth: {
        month: this.$moment().format("MM"),
        months: [
          { prop: "01", label: this.$t('jan') },
          { prop: "02", label: this.$t('feb') },
          { prop: "03", label: this.$t('mar') },
          { prop: "04", label: this.$t('apr') },
          { prop: "05", label: this.$t('may') },
          { prop: "06", label: this.$t('jun') },
          { prop: "07", label: this.$t('jul') },
          { prop: "08", label: this.$t('aug') },
          { prop: "09", label: this.$t('sep') },
          { prop: "10", label: this.$t('oct') },
          { prop: "11", label: this.$t('nov') },
          { prop: "12", label: this.$t('dec') }
        ]
      },
      sortby: {
        column: 'bill_start',
        order: 'asc',
        orders: [
          {
            prop: 'asc',
            label: this.$t('ascending')
          },
          {
            prop: 'desc',
            label: this.$t('descending')
          }
        ],
        columns: [
          {
            prop: 'name',
            label: this.$t('vpsname')
          },
          {
            prop: 'user_id',
            label: this.$t('vpsuser')
          },
          {
            prop: 'first_name',
            label: this.$t('firstname')
          },
          {
            prop: 'last_name',
            label: this.$t('lastname')
          },
          {
            prop: 'bill_start',
            label: this.$t('vpsbillstart')
          },
          {
            prop: 'bill_end',
            label: this.$t('vpsbillend')
          },
          {
            prop: 'bill_value',
            label: this.$t('vpsbillvalue')
          },
          {
            prop: 'billable_days',
            label: this.$t('daystxt')
          }
        ]
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name', 'user_id', 'first_name', 'last_name'],
      tableColumns: [
        {
          prop: 'name',
          label: this.$t('vpsname'),
          minWidth: 100
        },
        {
          prop: 'user_id',
          label: this.$t('vpsuser'),
          minWidth: 200
        },
        {
          prop: 'first_name',
          label: this.$t('firstname'),
          minWidth: 100
        },
        {
          prop: 'last_name',
          label: this.$t('lastname'),
          minWidth: 100
        },
        {
          prop: 'bill_start',
          label: this.$t('vpsbillstart'),
          minWidth: 100
        },
        {
          prop: 'bill_end',
          label: this.$t('vpsbillend'),
          minWidth: 100
        },
        {
          prop: 'bill_value',
          label: this.$t('vpsbillvalue')
        },
        {
          prop: 'billable_days',
          label: this.$t('vpsbilldays')
        }
      ],
      tableData: bills
    }
  },
  methods: {
    loadBilling(resp) {
      if (!data.success) {
        return ;
      }
      let data = resp.data;

      const now = this.$moment().format("YYYY-MM-DD");

      for(var i=0; i < data.length; i++) {
        let bill = data[i];
        bill.bill_start = this.$moment(bill.bill_start).format("YYYY-MM-DD");
        bill.bill_end = this.$moment(bill.bill_end).format("YYYY-MM-DD");
        if (bill.bill_end == now) {
          bill.bill_end = this.$t('today');
        }
        bills.push(data[i]);
      }
      // Sort and Overwrite Table
      bills.sort(this.sortIt);
      this.tableData = bills;
      // Show Table
      this.inprogress = false;
    },
    getBillMonth(month) {
      this.inprogress = true;
      bills = [];
      this.$getBilling(month, this.billyear.year)
        .then(this.loadBilling, this.failop);
    },
    getBillYear(year) {
      this.inprogress = true;
      bills = [];
      this.$getBilling(this.billmonth.month, year)
        .then(this.loadBilling, this.failop);
    },
    sortItNum (a, b) {
      if (this.sortby.order == 'asc') {
        return a[this.colSort] - b[this.colSort];
      }
      return b[this.colSort] - a[this.colSort];
    },
    sortIt (a, b) {
      if (this.sortby.order == 'asc') {
        if (a[this.colSort] < b[this.colSort])
          return -1;
        if (a[this.colSort] > b[this.colSort])
          return 1;
      }
      if (b[this.colSort] < a[this.colSort])
        return -1;
      if (b[this.colSort] > a[this.colSort])
        return 1;
      return 0;
    },
    sortByCol() {
      this.colSort = this.sortby.column;
      if (this.sortby.column == 'bill_value' || this.sortby.column == 'billable_days') {
        bills.sort(this.sortItNum);
      }
      else {
        bills.sort(this.sortIt);
      }
    },
    failop (error) {
      console.log(error);
    }
  },
  mounted () {
    bills = [];
    this.$getBilling()
      .then(this.loadBilling, this.failop);
  },
  created(){
    this.$setTranslatingInProgress()
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress")
  }
}
</script>
<style lang="scss">
.el-table .td-actions{
  button.btn {
    margin-right: 5px;
  }
}
</style>
